import { useState } from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import { Document, Page, pdfjs } from 'react-pdf';
import { AppLoader } from '@/components/AppLoader';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { IconTooltip } from '@/pages/Schedule/components';
import { Alert, Box, Icon } from '@/ui';
import type { PdfRendererProps } from './types';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const options = {
  standardFontDataUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

export default function PdfRenderer({ file, width }: PdfRendererProps) {
  const [numPages, setNumPages] = useState<number>(0);

  return (
    <Box pos="relative">
      {numPages > 1 && (
        <IconTooltip
          label={`This PDF file has ${numPages} pages. Only the first page will be converted.`}
          placement="top"
          icon={
            <Icon
              as={IoWarningOutline}
              bg="rgba(255, 255, 255, 0.9)"
              border="1px solid rgba(0, 0, 0, 0.10)"
              borderRadius="full"
              boxSize="7"
              cursor="help"
              p="1"
              pos="absolute"
              right="1"
              top="1"
              zIndex="1"
            />
          }
        />
      )}

      <Document
        error={<Alert variant="purple">Failed to load PDF file</Alert>}
        file={file}
        loading={<AppLoader />}
        options={options}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <Page
          pageNumber={1}
          renderForms={false}
          renderTextLayer={false}
          width={width}
        />
      </Document>
    </Box>
  );
}
